import { defineNuxtPlugin } from 'nuxt/app'
import { createMongoAbility } from '@casl/ability'
import { abilitiesPlugin } from '@casl/vue'
import { anonymousAbility } from '~~/server/utils/abilities'
import type { AppAbility } from '~~/server/types'

const ability = createMongoAbility<AppAbility>(anonymousAbility)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  })

  // Providing the ability as a helper for the nuxt context
  return {
    provide: {
      ability,
    },
  }
  // we can not use the Nuxt standard provide way which is loosing typings :
  // nuxtApp.provide("ability", ability);
})
