export default defineNuxtPlugin((nuxtApp) => {
  // for NuxtApp page hooks demo purpose
  nuxtApp.hook('page:start', () => {
    // console.info(`plugin:pageloader:nuxtApp.hook('page:start')`)
  })
  nuxtApp.hook('page:finish', () => {
    // console.info(`plugin:pageloader:nuxtApp.hook('page:finish')`)
  })

  // for testing
  // nuxtApp.callHook('page:start')
})
