import type { trpcRouterInput } from '~~/server/types/dvn-server'
import { useNuxtApp, ref, defineStore } from '#imports'

export const useClientInfo = defineStore('clientInfo', () => {
  type ClientInfo = trpcRouterInput['sys_Authentication']['saveUserAccess']

  const { $trpc, $ipc } = useNuxtApp()

  const getClient = async () => {
    if ($ipc) {
      const info = await $ipc.getSystemInfo()
      return JSON.stringify({
        version: info.system.version,
        platform: info.system.platform,
        arch: info.system.architecture,
      })
    } else return 'web'
  }
  const getClientInfo = async () => {
    const client = await getClient()
    return {
      browserLanguage:
        import.meta.client && navigator && navigator.language ? navigator.language : '',
      client,
    }
  }

  const clientInfo = ref<ClientInfo>()

  /**
   * Save user access
   */
  const saveUserAccess = async () => {
    if (import.meta.client) {
      clientInfo.value = await getClientInfo()
      await $trpc.sys_Authentication.saveUserAccess.mutate(clientInfo.value)
    }
  }

  return {
    clientInfo,
    saveUserAccess,
  }
})
