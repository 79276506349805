/**
 * Bind Electron IPC API to Nuxt context as $ipc
 * use: const { $ipc } = useNuxtApp()
 */
export default defineNuxtPlugin((nuxtApp) => {
  // 👉 Detect if Electron context is available
  function getIpcApi() {
    if (import.meta.client && window && window.ipc) return window.ipc
    return undefined
  }

  const ipc = getIpcApi()

  // Providing the ipc object
  return {
    provide: {
      ipc,
    },
  }
  // we can not use the Nuxt standard provide way which is loosing typings :
  // nuxtApp.provide('ipc', ipc) // not working
})
