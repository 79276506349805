import type { RawRuleOf } from '@casl/ability'
import type { trpcRouterOutput, AppAbility } from '~~/server/types'
import { anonymousAbility } from '~~/server/utils/abilities'
import type {
  LoginFormData,
  RegisterUserFormData,
  ForgotPasswordFormData,
  ResetPasswordFormData,
  ChangePasswordFormData,
  UpdateProfileFormData,
} from '~~/types'
import { ref, computed, useNuxtApp, defineStore, useCookie, acceptHMRUpdate } from '#imports'

export const useUserStore = defineStore('userStore', () => {
  type User = trpcRouterOutput['sys_UserProfile']['getProfile']
  type SessionData = {
    user: User
    token: string
  }

  const { $trpc, $ability } = useNuxtApp()

  /**
   * STORE
   */
  const user = ref<User>()
  const abilities = ref<RawRuleOf<AppAbility>[]>()
  const sessionToken = ref<string>()
  const USER_NOT_FOUND = 'User not found'

  /**
   * computed
   */

  const isAuthenticated = computed(() => {
    return user.value ? true : false
  })

  const isAdminRole = computed(() => {
    const role = !!user.value && user.value.role ? user.value.role : null
    return role === 'admin' ? true : false
  })

  const isUserRole = computed(() => {
    const role = !!user.value && user.value.role ? user.value.role : null
    return role === 'user' ? true : false
  })

  const isVerified = computed(() => {
    return !!user.value && user.value.verified
  })

  const isDisabled = computed(() => {
    return !!user.value && user.value.disabled
  })

  const isDeleted = computed(() => {
    return !!user.value && user.value.deleted
  })

  const isArchived = computed(() => {
    return !!user.value && user.value.archived
  })

  const isStripeConnectedAccount = computed(() => {
    return user.value?.stripeAccountId && user.value?.stripeAccountCompleted ? true : false
  })

  const canBuy = computed(() => {
    return (
      !!user.value &&
      user.value.verified &&
      !user.value.disabled &&
      !user.value.deleted &&
      !user.value.archived
    )
  })

  /**
   * reset user data and remove cookie
   */
  const reset = () => {
    // clean user state
    user.value = undefined
    abilities.value = undefined
    sessionToken.value = undefined

    // restore initial ability
    $ability.update(anonymousAbility)

    // remove cookie
    useCookie('sessionToken').value = undefined // remove cookie when logout
  }

  /**
   * Load user data to state and set cookie
   */
  const loadUserData = async () => {
    const data = await $fetch<SessionData>('/api/auth/session', {
      headers: {
        Cookie: `sessionToken=${useCookie('sessionToken').value}`, // forward sessionToken cookie on server side, needed to authenticate user from cookie on first request
      },
    })
    if (!data && user.value) {
      reset()
    }
    if (data) {
      user.value = data.user
      sessionToken.value = data.token
    }
    if (user.value) {
      abilities.value = <RawRuleOf<AppAbility>[]>user.value.abilities
      $ability.update(abilities.value) // update abilities
    }
  }

  /**
   * login user with email and password
   */
  const login = async (formData: LoginFormData) => {
    return await $trpc.sys_Authentication.login.mutate({
      credentials: formData,
    })
  }

  /**
   * logout user
   */
  const logout = () => {
    reset()
  }

  /**
   * register user with email and password
   */
  const register = async (formData: RegisterUserFormData) => {
    return await $trpc.sys_Authentication.registerUser.mutate(formData)
  }

  /**
   * verify user
   */
  const verifyUser = async (uuid: string): Promise<{ email: string; verified: boolean }> => {
    if (!user.value) throw new Error(USER_NOT_FOUND)
    return await $trpc.sys_Authentication.verifyUser.mutate({
      email: user.value.email,
      uuid,
    })
  }

  /**
   * forgot password
   */
  const forgotPassword = async (formData: ForgotPasswordFormData) => {
    return await $trpc.sys_Authentication.forgotPassword.mutate(formData)
  }

  /**
   * reset password
   */
  const resetPassword = async (formData: ResetPasswordFormData) => {
    return await $trpc.sys_Authentication.resetPassword.mutate(formData)
  }

  /**
   * change password
   */
  const changePassword = async (formData: ChangePasswordFormData) => {
    return await $trpc.sys_Authentication.changePassword.mutate(formData)
  }

  /**
   * save user profile
   */
  const updateProfile = async (formData: UpdateProfileFormData) => {
    const r = await $trpc.sys_UserProfile.updateProfile.mutate(formData)
    await loadUserData()
    return r
  }

  /**
   * save user onboarding state
   */
  const updateOnboarded = async (completed: boolean) => {
    const r = await $trpc.sys_UserProfile.updateOnboarded.mutate(completed)
    await loadUserData()
    return r
  }

  /**
   * add uploaded samples
   */
  const addUploadedSamples = async (samples: number) => {
    return await $trpc.sys_Users.addUploadedSamples.mutate(samples)
  }

  /**
   * change email
   */
  const changeEmail = async (email: string) => {
    return await $trpc.sys_UserProfile.changeEmail.mutate(email)
  }

  /**
   * verify email
   */
  const verifyEmail = async (uuid: string) => {
    user.value = await $trpc.sys_UserProfile.verifyEmail.mutate(uuid)
  }

  /**
   * deactivate account
   */
  const deactivateAccount = async () => {
    await $trpc.sys_UserProfile.deactivateAccount.mutate()
  }

  /**
   * save current theme to private preferences
   */
  const saveTheme = async (theme: 'light' | 'dark') => {
    await $trpc.sys_Users.saveTheme.mutate(theme)
    await loadUserData()
  }

  /**
   * save current local to private preferences
   */
  const saveLocale = async (locale: string) => {
    await $trpc.sys_Users.saveLocale.mutate(locale)
    await loadUserData()
  }

  /**
   * save cart
   */
  const saveCart = async (instrument_ids: string[]) => {
    await $trpc.sys_Users.saveCart.mutate(instrument_ids)
    await loadUserData()
  }

  /**
   * save downloaded instruments
   */
  const updateDownloadedInstrument = async (id: string) => {
    await $trpc.sys_Users.updateDownloadedInstrument.mutate(id)
    await loadUserData()
  }

  /**
   * add favorite instrument
   */
  async function addFavoriteInstrument(id: string) {
    await $trpc.sys_Users.addFavoriteInstrument.mutate(id)
    await loadUserData()
  }

  /**
   * remove favorite instrument
   */
  async function removeFavoriteInstrument(id: string) {
    await $trpc.sys_Users.removeFavoriteInstrument.mutate(id)
    await loadUserData()
  }

  /**
   * is favorite instrument
   */
  function isFavoriteInstrument(id: string) {
    const ids = user.value?.favorites?.instrument_ids
    if (ids) {
      return ids.map((e) => e.toString()).includes(id)
    } else return false
  }

  /**
   * add/remove favorite instrument
   */
  async function addRemoveFavoriteInstrument(id: string) {
    if (isFavoriteInstrument(id)) await removeFavoriteInstrument(id)
    else await addFavoriteInstrument(id)
  }

  /**
   * add favorite video
   */
  async function addFavoriteVideo(videoId: string) {
    await $trpc.sys_Users.addFavoriteVideo.mutate(videoId)
    await loadUserData()
  }

  /**
   * remove favorite video
   */
  async function removeFavoriteVideo(videoId: string) {
    await $trpc.sys_Users.removeFavoriteVideo.mutate(videoId)
    await loadUserData()
  }

  /**
   * is favorite video
   */
  function isFavoriteVideo(videoId: string) {
    return user.value?.learning?.favorite_video_ids?.includes(videoId)
  }

  /**
   * add/remove favorite video
   */
  async function addRemoveFavoriteVideo(id: string) {
    if (isFavoriteVideo(id)) await removeFavoriteVideo(id)
    else await addFavoriteVideo(id)
  }

  /**
   * add seen video
   */
  async function addSeenVideo(id: string) {
    await $trpc.sys_Users.addSeenVideo.mutate(id)
    await loadUserData()
  }

  /**
   * remove seen video
   */
  async function removeSeenVideo(id: string) {
    await $trpc.sys_Users.removeSeenVideo.mutate(id)
    await loadUserData()
  }

  /**
   * is seen video
   */
  function isSeenVideo(id: string) {
    return user.value?.learning?.seen_video_ids?.includes(id)
  }

  /**
   * add/remove seen video video
   */
  async function addRemoveSeenVideo(id: string) {
    if (isSeenVideo(id)) await removeSeenVideo(id)
    else await addSeenVideo(id)
  }

  return {
    // states variables
    user,
    abilities,
    sessionToken,

    // computed
    isAuthenticated,
    isAdminRole,
    isUserRole,
    isVerified,
    isDisabled,
    isDeleted,
    isArchived,
    isStripeConnectedAccount,
    canBuy,

    // functions
    loadUserData,
    login,
    logout,
    register,
    verifyUser,
    forgotPassword,
    resetPassword,
    changePassword,
    updateProfile,
    updateOnboarded,
    addUploadedSamples,
    changeEmail,
    verifyEmail,
    deactivateAccount,
    saveTheme,
    saveLocale,
    saveCart,
    updateDownloadedInstrument,
    addFavoriteInstrument,
    removeFavoriteInstrument,
    isFavoriteInstrument,
    addRemoveFavoriteInstrument,
    addFavoriteVideo,
    removeFavoriteVideo,
    isFavoriteVideo,
    addRemoveFavoriteVideo,
    addSeenVideo,
    removeSeenVideo,
    isSeenVideo,
    addRemoveSeenVideo,
  }
})

// Pinia supports Hot Module replacement, https://pinia.vuejs.org/cookbook/hot-module-replacement.html
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
