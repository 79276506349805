import type { RawRuleOf } from '@casl/ability'
import type { AppAbility } from '../types/dvn-casl'

/**
 * Define role abilities
 */
export const anonymousAbility: RawRuleOf<AppAbility>[] = [
  {
    action: 'read',
    subject: 'auth',
  },
]

export const userAbility: RawRuleOf<AppAbility>[] = [
  {
    action: 'read',
    subject: 'auth',
  },
  {
    action: 'manage',
    subject: 'catalog',
  },
  {
    action: 'manage',
    subject: 'user-profile',
  },
]

export const adminAbility: RawRuleOf<AppAbility>[] = [
  {
    action: 'manage',
    subject: 'all',
  },
]
