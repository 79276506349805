import { createVuetify } from 'vuetify'

// directives
import * as directives from 'vuetify/directives'

// components
// see: https://vuetifyjs.com/en/getting-started/installation/#js
import * as VAlert from 'vuetify/components/VAlert'
import * as VApp from 'vuetify/components/VApp'
import * as VAppBar from 'vuetify/components/VAppBar'
import * as VAutocomplete from 'vuetify/components/VAutocomplete'
import * as VAvatar from 'vuetify/components/VAvatar'
import * as VBadge from 'vuetify/components/VBadge'
import * as VBtn from 'vuetify/components/VBtn'
import * as VBtnGroup from 'vuetify/components/VBtnGroup'
import * as VBtnToggle from 'vuetify/components/VBtnToggle'
import * as VCard from 'vuetify/components/VCard'
import * as VCarousel from 'vuetify/components/VCarousel'
import * as VCheckbox from 'vuetify/components/VCheckbox'
import * as VChip from 'vuetify/components/VChip'
import * as VChipGroup from 'vuetify/components/VChipGroup'
import * as VDialog from 'vuetify/components/VDialog'
import * as VDivider from 'vuetify/components/VDivider'
import * as VDataTable from 'vuetify/components/VDataTable'
import * as VForm from 'vuetify/components/VForm'
import * as VGrid from 'vuetify/components/VGrid'
import * as VIcon from 'vuetify/components/VIcon'
import * as VImg from 'vuetify/components/VImg'
import * as VInput from 'vuetify/components/VInput'
import * as VLabel from 'vuetify/components/VLabel'
import * as VList from 'vuetify/components/VList'
import * as VLocaleProvider from 'vuetify/components/VLocaleProvider'
import * as VMain from 'vuetify/components/VMain'
import * as VMenu from 'vuetify/components/VMenu'
import * as VNavigationDrawer from 'vuetify/components/VNavigationDrawer'
import * as VOverlay from 'vuetify/components/VOverlay'
import * as VPagination from 'vuetify/components/VPagination'
import * as VProgressLinear from 'vuetify/components/VProgressLinear'
import * as VRadio from 'vuetify/components/VRadio'
import * as VRadioGroup from 'vuetify/components/VRadioGroup'
import * as VSelect from 'vuetify/components/VSelect'
import * as VSheet from 'vuetify/components/VSheet'
import * as VSlider from 'vuetify/components/VSlider'
import * as VSlideGroup from 'vuetify/components/VSlideGroup'
import * as VSwitch from 'vuetify/components/VSwitch'
import * as VTabs from 'vuetify/components/VTabs'
import * as VTextarea from 'vuetify/components/VTextarea'
import * as VTextField from 'vuetify/components/VTextField'
import * as VThemeProvider from 'vuetify/components/VThemeProvider'
import * as VToolbar from 'vuetify/components/VToolbar'
import * as VTooltip from 'vuetify/components/VTooltip'
import * as VWindow from 'vuetify/components/VWindow'

// labs components

import { icons } from './icons'

export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useAppConfig()
  const vuetify = createVuetify({
    ssr: true,
    display: {
      mobileBreakpoint: 'sm',
    },
    components: {
      ...VAlert,
      ...VApp,
      ...VAppBar,
      ...VAutocomplete,
      ...VAvatar,
      ...VBadge,
      ...VBtn,
      ...VBtnGroup,
      ...VBtnToggle,
      ...VCard,
      ...VCarousel,
      ...VCheckbox,
      ...VChip,
      ...VChipGroup,
      ...VDialog,
      ...VDivider,
      ...VForm,
      ...VGrid,
      ...VIcon,
      ...VImg,
      ...VInput,
      ...VLabel,
      ...VList,
      ...VLocaleProvider,
      ...VMain,
      ...VMenu,
      ...VNavigationDrawer,
      ...VOverlay,
      ...VPagination,
      ...VProgressLinear,
      ...VRadio,
      ...VRadioGroup,
      ...VSelect,
      ...VSheet,
      ...VSlider,
      ...VSlideGroup,
      ...VSwitch,
      ...VTabs,
      ...VTextarea,
      ...VTextField,
      ...VThemeProvider,
      ...VToolbar,
      ...VTooltip,
      ...VWindow,

      // labs components
      ...VDataTable,
    },
    directives,
    defaults: appConfig.vuetifyConfig.defaults,
    icons,
    theme: appConfig.vuetifyConfig.theme,
  })

  nuxtApp.vueApp.use(vuetify)
})
