import { breakpointsVuetify } from '@vueuse/core'
import type { VuetifyOptions } from 'vuetify'
import {
  Skins,
  ContentLayoutNav,
  ContentWidth,
  FooterType,
  NavbarType,
  HorizontalNavType,
} from '~~/plugins/layouts/enums'
import type { SiteConfig, ThemeConfig } from '~~/types/dvn-layouts'

export default defineAppConfig({
  // site config
  siteConfig: {
    headerHeight: 60, // in pixels
  } as SiteConfig,
  // theme config
  themeConfig: {
    app: {
      contentWidth: ContentWidth.Boxed,
      contentLayoutNav: ContentLayoutNav.Vertical,
      overlayNavFromBreakpoint: breakpointsVuetify.md + 16, // 16 for scrollbar. Docs: https://next.vuetifyjs.com/en/features/display-and-platform/
      enableI18n: true,
      theme: 'dark',
      isRtl: false,
      skin: Skins.Default,
      iconRenderer: 'VIcon',
    },
    navbar: {
      type: NavbarType.Sticky,
      navbarBlur: true,
    },
    footer: { type: FooterType.Static },
    verticalNav: {
      isVerticalNavCollapsed: false,
      defaultNavItemIconProps: { icon: 'mdi-circle-outline' },
      isVerticalNavSemiDark: false,
    },
    horizontalNav: {
      type: HorizontalNavType.Sticky,
      transition: 'slide-y-reverse-transition',
    },
    icons: {
      chevronDown: { icon: 'mdi-chevron-down' },
      chevronRight: { icon: 'mdi-chevron-right' },
      close: { icon: 'mdi-close' },
      verticalNavPinned: { icon: 'mdi-radiobox-marked' },
      verticalNavUnPinned: { icon: 'mdi-radiobox-blank' },
      sectionTitlePlaceholder: { icon: 'mdi-minus' },
    },
  } as ThemeConfig,

  // vuetify config
  vuetifyConfig: {
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: '#03A9F4',
            secondary: '#F066F0',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51',
            'on-primary': '#FFFFFF',
            background: '#F2F2F2',
            'on-background': '#333333',
            surface: '#FFFFFF',
            'on-surface': '#333333',
            'grey-50': '#FAFAFA',
            'grey-100': '#F5F5F5',
            'grey-200': '#EEEEEE',
            'grey-300': '#E0E0E0',
            'grey-400': '#BDBDBD',
            'grey-500': '#9E9E9E',
            'grey-600': '#757575',
            'grey-700': '#616161',
            'grey-800': '#424242',
            'grey-900': '#212121',
            'perfect-scrollbar-thumb': '#D9D9D9',
          },

          variables: {
            'border-color': '#333333',

            // Shadows
            'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.08)',
            'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.12)',
            'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: '#03A9F4',
            secondary: '#F066F0',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51',
            'on-primary': '#000000',
            background: '#111111',
            'on-background': '#E6E6E6',
            surface: '#000000',
            'on-surface': '#E6E6E6',
            'grey-50': '#2A2E42',
            'grey-100': '#2F3349',
            'grey-200': '#4A5072',
            'grey-300': '#5E6692',
            'grey-400': '#7983BB',
            'grey-500': '#8692D0',
            'grey-600': '#AAB3DE',
            'grey-700': '#B6BEE3',
            'grey-800': '#CFD3EC',
            'grey-900': '#E7E9F6',
            'perfect-scrollbar-thumb': '#262626',
          },
          variables: {
            'border-color': '#E6E6E6',

            // Shadows
            'shadow-key-umbra-opacity': 'rgba(20, 18, 33, 0.08)',
            'shadow-key-penumbra-opacity': 'rgba(20, 18, 33, 0.12)',
            'shadow-key-ambient-opacity': 'rgba(20, 18, 33, 0.04)',
          },
        },
      },
    } as VuetifyOptions['theme'],
    defaults: {
      VAlert: {
        density: 'comfortable',
      },
      VAvatar: {
        // ℹ️ Remove after next release
        variant: 'flat',
      },
      VBadge: {
        // set v-badge default color to primary
        color: 'primary',
      },
      VBtn: {
        // set v-btn default color to primary
        color: 'primary',
        rounded: 'xl',
      },
      VChip: {
        elevation: 0,
      },
      VMenu: {
        VList: {
          density: 'default',
          color: 'primary',
        },
      },
      VPagination: {
        activeColor: 'primary',
        density: 'comfortable',
      },
      VTabs: {
        // set v-tabs default color to primary
        color: 'primary',
        VSlideGroup: {
          showArrows: true,
        },
      },
      VTooltip: {
        // set v-tooltip default location to top
        location: 'top',
      },
      // VList: {
      //   VListItem: {
      //     color: 'primary',
      //   },
      // },
      VCheckbox: {
        // set v-checkbox default color to primary
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
      },
      VRadioGroup: {
        color: 'primary',
        density: 'comfortable',
        hideDetails: 'auto',
      },
      VRadio: {
        density: 'comfortable',
        hideDetails: 'auto',
      },
      VSelect: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VRangeSlider: {
        // set v-range-slider default color to primary
        color: 'primary',
        density: 'comfortable',
        thumbLabel: true,
        hideDetails: 'auto',
      },
      VRating: {
        // set v-rating default color to primary
        color: 'warning',
      },
      VProgressCircular: {
        // set v-progress-circular default color to primary
        color: 'primary',
      },
      VSlider: {
        // set v-slider default color to primary
        color: 'primary',
        hideDetails: 'auto',
      },
      VTextField: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VAutocomplete: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VCombobox: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VFileInput: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VTextarea: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
      },
      VSwitch: {
        // set v-switch default color to primary
        color: 'primary',
        hideDetails: 'auto',
      },
      VDataTableServer: {
        hover: true,
        itemsPerPage: 25,
        itemsPerPageOptions: [
          { value: 25, title: '25' },
          { value: 50, title: '50' },
          { value: 100, title: '100' },
          { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
        ],
      },
    } as VuetifyOptions['defaults'],
  } as VuetifyOptions,
})
