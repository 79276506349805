import { useRuntimeConfig, useNuxtApp, useLocaleHead, useSeoMeta, useHead } from '#imports'
import type { SeoPageMeta } from '~~/types'

export function usePageSeoMeta() {
  const config = useRuntimeConfig()
  const { t } = useNuxtApp().$i18n // recommended workaround useNuxtApp().$i18n when const { t } = useI18n() not working in composables

  // i18n SEO attributes, see https://v8.i18n.nuxtjs.org/api/composables#uselocalehead
  function setLocalizedHeadAttributes() {
    const i18nHead = useLocaleHead({
      addDirAttribute: true,
      addSeoAttributes: true,
    })
    useHead({
      htmlAttrs: {
        lang: i18nHead.value.htmlAttrs?.lang,
      },
      link: [...(i18nHead.value.link || [])],
      meta: [...(i18nHead.value.meta || [])],
    })
  }

  // set localized SEO meta
  function setLocalizedSeoMeta() {
    const route = useRoute()

    function getTitle() {
      if (typeof route.meta.title === 'string') return getConcatenatedTitle(t(route.meta.title))
      else return config.public.siteName
    }

    function getDescription() {
      if (typeof route.meta.description === 'string') return t(route.meta.description)
      else return ''
    }

    useSeoMeta({
      title: () => getTitle(),
      ogTitle: () => getTitle(),
      description: () => getDescription(),
      ogDescription: () => getDescription(),
      ogImage: () => `${config.public.siteUrl}/logo.png`,
      // twitterCard: 'summary_large_image',
    })
  }

  function getConcatenatedTitle(title: string) {
    return `${title} | ${config.public.siteName}`
  }

  // set SEO meta
  function setSeoMeta(pageMeta: SeoPageMeta) {
    // setLocalizedSeoMeta()

    useSeoMeta({
      title: () => getConcatenatedTitle(pageMeta.title),
      ogTitle: () => getConcatenatedTitle(pageMeta.title),
      description: () => pageMeta.description,
      ogDescription: () => pageMeta.description,
      ogImage: () => `${config.public.siteUrl}/logo.png`,
      // twitterCard: 'summary_large_image',
    })

    // FIXME: ogImage workaround:

    // route.meta.ogImage = useAzBlobUrl().getImageUrl(data.value.image) // not working, returns default ogImage: `${config.public.siteUrl}/logo.png`
    if (pageMeta.image) useSeoMeta({ ogImage: pageMeta.image })
  }

  return {
    setLocalizedHeadAttributes,
    setLocalizedSeoMeta,
    setSeoMeta,
  }
}
