<script setup lang="ts">
import type { NuxtError } from '#app'
import { useError } from '#imports'

const props = defineProps({
  error: Object,
})

const { t } = useI18n()
const { setSeoMeta } = usePageSeoMeta()
setSeoMeta({ title: t('pages.500.title') })
const e = useError()
const code = (e.value as NuxtError).statusCode
  ? (e.value as NuxtError).statusCode.toString()
  : '500'
const message = t((e.value as NuxtError).message)
const handleError = async () => await clearError({ redirect: '/' })
</script>

<template>
  <body>
    <div class="misc-wrapper">
      <div class="text-center mb-4">
        <!-- 👉 Title and subtitle -->
        <h1 class="text-h1">
          {{ code }}
        </h1>
        <h5 class="text-h5 mb-1">
          {{ message }}
        </h5>
        <p>
          {{ $t('pages.500.something went wrong') }}
        </p>
      </div>

      <!-- 👉 Image -->
      <div class="misc-avatar w-100 text-center">
        <VImg
          src="/images/error.jpg"
          alt="Internal server error"
          :max-width="800"
          class="mx-auto"
        />
        <VBtn class="mt-10" @click="handleError"> {{ $t('Back to Home') }} </VBtn>
      </div>
    </div>
  </body>
</template>

<style lang="scss" scoped>
@use '@styles/pages/misc.scss';
body,
p,
.text-h1,
.text-h5 {
  color: white;
  background-color: #020202;
}
</style>
