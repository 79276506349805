import { useRuntimeConfig, useNuxtApp } from 'nuxt/app'
import type { RouteLocationNormalized } from 'vue-router'
import { anonymousAbility, userAbility, adminAbility } from '../../server/utils/abilities'
import type { Actions, Subjects } from '~~/server/types'

export const useAppAuthorizations = () => {
  /**
   * return runtime config value
   */

  // const { enableAuthorisations } = useRuntimeConfig().public
  const { $ability } = useNuxtApp()

  /**
   * Returns ability result if ACL is configured or else just return true
   * We should allow passing string | undefined to can because for admin ability we omit defining action & subject
   *
   * Useful if you don't know if ACL is configured or not
   * Used in @theme files to handle absence of ACL without errors
   *
   * @param {Actions} action CASL Actions // https://casl.js.org/v4/en/guide/intro#basics
   * @param {Subjects} subject CASL Subject // https://casl.js.org/v4/en/guide/intro#basics
   */
  const can = (action: Actions, subject: Subjects) => {
    // if (!enableAuthorisations) return true
    return $ability.can(action, subject)
  }

  /**
   * Check if route is allowed to navigate
   */
  const canNavigate = (to: RouteLocationNormalized) => {
    // if (!enableAuthorisations) return true
    return to.matched.some((route) =>
      $ability.can(route.meta.caslAction as Actions, route.meta.caslSubject as Subjects),
    )
  }

  /**
   * Check if route is allowed to navigate
   */
  const getAbilitiesFromRole = (role: string) => {
    switch (role) {
      case 'admin':
        return adminAbility
      case 'user':
        return userAbility
      default:
        return anonymousAbility
    }
  }

  return {
    can,
    canNavigate,
    getAbilitiesFromRole,
  }
}
