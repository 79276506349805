// import { NuxtError } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  /*
  nuxtApp.hook('vue:error', (..._args) => {
    console.info('vue:error')
    if (import.meta.client) {
      console.info(..._args)
    }
  })
  nuxtApp.hook('app:error', (..._args) => {
    console.info('app:error')
    if (import.meta.client) {
      console.info(..._args)
    }
  })
  nuxtApp.vueApp.config.errorHandler = (..._args) => {
    console.info('global error handler')
    if (import.meta.client) {
      console.info(..._args)
    }
  }
  */
})
