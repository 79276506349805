import type { InjectionKey, Ref } from 'vue'

export const injectionKeyIsVerticalNavHovered: InjectionKey<Ref<boolean>> =
  Symbol('isVerticalNavHovered')

// ℹ️ We generate layout config from our themeConfig so you don't have to write config twice
export default defineNuxtPlugin((nuxtApp) => {
  const appConfig = useAppConfig()

  nuxtApp.vueApp.use(() => {
    useDynamicVhCssProperty()
  })

  // Providing the themeConfig as a helper for the nuxt context
  const themeConfig = appConfig.themeConfig
  return {
    provide: {
      themeConfig,
    },
  }
  // we can not use the Nuxt standard provide way which is loosing typings :
  // nuxtApp.provide('themeConfig', appConfig.themeConfig)
})
