export default defineNuxtRouteMiddleware(async (to, from) => {
  // console.info('2.i18n.global:', { from, to })

  const userStore = useUserStore()

  // if logged user
  if (userStore.isAuthenticated) {
    const { $i18n } = useNuxtApp()
    const getRouteBaseName = useRouteBaseName()

    // init user local preference if empty
    if (!userStore.user?.privatePreferences || !userStore.user?.privatePreferences.locale) {
      // console.log('init user local preference')
      userStore.saveLocale($i18n.locale.value)
    }

    if (
      userStore.user?.privatePreferences &&
      userStore.user?.privatePreferences.locale &&
      $i18n.locale.value !== userStore.user?.privatePreferences.locale
    ) {
      // console.log('sync locale with user preference')
      const config = useRuntimeConfig()
      if (typeof config.public.localeCookie === 'undefined')
        throw new Error('config.public.localeCookie is undefined')
      useCookie<string>(config.public.localeCookie).value =
        userStore.user?.privatePreferences.locale

      $i18n.locale.value = userStore.user?.privatePreferences.locale

      // redirect to user local preferences
      if (!to.name?.toString().endsWith($i18n.locale.value)) {
        return await navigateTo(localeLink({ name: getRouteBaseName(to) }), {
          replace: true,
        })
      }
    }
  }
})
