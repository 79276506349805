import type { RouterConfig } from '@nuxt/schema'
import { createWebHistory } from 'vue-router'

export default <RouterConfig>{
  history: (base) => (import.meta.client ? createWebHistory(base) : null),

  routes: (_routes) => [
    /**
     * place holder to remap routes if needed
     */
    // {
    //   path: '/user/settings',
    //   redirect: () => ({ name: 'account-settings-tab', params: { tab: 'account' } }),
    // },
    ..._routes,
  ],
}
