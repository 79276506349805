import { useTheme } from 'vuetify'
import { VThemeProvider } from 'vuetify/components'
// TODO: Use `VThemeProvider` from dist instead of lib (Using this component from dist causes navbar to loose sticky positioning)

import type { MaybeRef } from '@vueuse/shared'
import type { Ref } from 'vue'
import { ContentLayoutNav, NavbarType } from '~~/plugins/layouts/enums'
import { injectionKeyIsVerticalNavHovered } from '~~/plugins/layouts/index'
import type { ThemeConfig } from '~~/types'

export const useThemeConfig = () => {
  const $themeConfig: ThemeConfig = useNuxtApp().$themeConfig

  const setAppDir = (dir: 'ltr' | 'rtl') => {
    document.documentElement.setAttribute('dir', dir)
  }

  const navbarType = computed({
    get() {
      return $themeConfig.navbar.type
    },
    set(value: typeof $themeConfig.navbar.type) {
      $themeConfig.navbar.type = value
    },
  })

  const isNavbarBlurEnabled = computed({
    get() {
      return $themeConfig.navbar.navbarBlur
    },
    set(value: typeof $themeConfig.navbar.navbarBlur) {
      $themeConfig.navbar.navbarBlur = value
    },
  })

  const footerType = computed({
    get() {
      return $themeConfig.footer.type
    },
    set(value: typeof $themeConfig.footer.type) {
      $themeConfig.footer.type = value
    },
  })

  const isVerticalNavCollapsed = computed({
    get() {
      return $themeConfig.verticalNav.isVerticalNavCollapsed
    },
    set(value: typeof $themeConfig.verticalNav.isVerticalNavCollapsed) {
      $themeConfig.verticalNav.isVerticalNavCollapsed = value
    },
  })

  const appContentWidth = computed({
    get() {
      return $themeConfig.app.contentWidth
    },
    set(value: typeof $themeConfig.app.contentWidth) {
      $themeConfig.app.contentWidth = value
    },
  })

  const appContentLayoutNav = computed({
    get() {
      return $themeConfig.app.contentLayoutNav
    },
    set(value: typeof $themeConfig.app.contentLayoutNav) {
      $themeConfig.app.contentLayoutNav = value

      // If Navbar type is hidden while switching to horizontal nav => Reset it to sticky
      if (value === ContentLayoutNav.Horizontal) {
        if (navbarType.value === NavbarType.Hidden) navbarType.value = NavbarType.Sticky
        isVerticalNavCollapsed.value = false
      }
    },
  })

  const horizontalNavType = computed({
    get() {
      return $themeConfig.horizontalNav.type
    },
    set(value: typeof $themeConfig.horizontalNav.type) {
      $themeConfig.horizontalNav.type = value
    },
  })

  const isLessThanOverlayNavBreakpoint = computed(() => {
    return (windowWidth: MaybeRef<number>) =>
      unref(windowWidth) < $themeConfig.app.overlayNavFromBreakpoint
  })

  const layoutClasses = computed(
    () => (windowWidth: MaybeRef<number>, windowScrollY: MaybeRef<number>) => {
      const route = useRoute()

      return [
        `layout-nav-type-${appContentLayoutNav.value}`,
        `layout-navbar-${navbarType.value}`,
        `layout-footer-${footerType.value}`,
        {
          'layout-vertical-nav-collapsed':
            isVerticalNavCollapsed.value &&
            appContentLayoutNav.value === 'vertical' &&
            !isLessThanOverlayNavBreakpoint.value(windowWidth),
        },
        {
          [`horizontal-nav-${horizontalNavType.value}`]: appContentLayoutNav.value === 'horizontal',
        },
        `layout-content-width-${appContentWidth.value}`,
        { 'layout-overlay-nav': isLessThanOverlayNavBreakpoint.value(windowWidth) },
        { 'window-scrolled': unref(windowScrollY) },
        route.meta.layoutWrapperClasses ? route.meta.layoutWrapperClasses : null,
      ]
    },
  )

  const switchToVerticalNavOnLtOverlayNavBreakpoint = (windowWidth: MaybeRef<number>) => {
    /*
      ℹ️ This is flag will hold nav type need to render when switching between lgAndUp from mdAndDown window width

      Requirement: When we nav is set to `horizontal` and we hit the `mdAndDown` breakpoint nav type shall change to `vertical` nav
      Now if we go back to `lgAndUp` breakpoint from `mdAndDown` how we will know which was previous nav type in large device?

      Let's assign value of `appContentLayoutNav` as default value of lgAndUpNav. Why 🤔?
        If template is viewed in lgAndUp
          We will assign `appContentLayoutNav` value to `lgAndUpNav` because at this point both constant is same
          Hence, for `lgAndUpNav` it will take value from theme config file
        else
          It will always show vertical nav and if user increase the window width it will fallback to `appContentLayoutNav` value
          But `appContentLayoutNav` will be value set in theme config file
    */
    const lgAndUpNav = ref(appContentLayoutNav.value)

    /*
      There might be case where we manually switch from vertical to horizontal nav and vice versa in `lgAndUp` screen
      So when user comes back from `mdAndDown` to `lgAndUp` we can set updated nav type
      For this we need to update the `lgAndUpNav` value if screen is `lgAndUp`
    */
    watch(appContentLayoutNav, (value) => {
      if (!isLessThanOverlayNavBreakpoint.value(windowWidth)) lgAndUpNav.value = value
    })

    /*
      This is layout switching part
      If it's `mdAndDown` => We will use vertical nav no matter what previous nav type was
      Or if it's `lgAndUp` we need to switch back to `lgAndUp` nav type. For this we will tracker property `lgAndUpNav`
    */
    watch(
      () => isLessThanOverlayNavBreakpoint.value(windowWidth),
      (val) => {
        if (!val) appContentLayoutNav.value = lgAndUpNav.value
        else appContentLayoutNav.value = ContentLayoutNav.Vertical
      },
      { immediate: true },
    )
  }

  /*
    This function will return true if current state is mini. Mini state means vertical nav is:
      - Collapsed
      - Isn't hovered by mouse
      - nav is not less than overlay breakpoint (hence, isn't overlay menu)

    ℹ️ We are getting `isVerticalNavHovered` as param instead of via `inject` because
        we are using this in `VerticalNav.vue` component which provide it and I guess because
        same component is providing & injecting we are getting undefined error
  */
  const isVerticalNavMini = (
    windowWidth: MaybeRef<number>,
    isVerticalNavHovered: Ref<boolean> | null = null,
  ) => {
    const isVerticalNavHoveredLocal =
      isVerticalNavHovered || inject(injectionKeyIsVerticalNavHovered) || ref(false)

    return computed(
      () =>
        isVerticalNavCollapsed.value &&
        !isVerticalNavHoveredLocal.value &&
        !isLessThanOverlayNavBreakpoint.value(unref(windowWidth)),
    )
  }

  const dynamicI18nProps = computed(() => (key: string, tag = 'span') => {
    if ($themeConfig.app.enableI18n) {
      return {
        keypath: key,
        tag,
        scope: 'global',
      }
    }
    return {}
  })

  const isAppRtl = computed({
    get() {
      return $themeConfig.app.isRtl === true ? true : false
    },
    set(value: typeof $themeConfig.app.isRtl) {
      $themeConfig.app.isRtl = value
      setAppDir(value ? 'rtl' : 'ltr')
    },
  })

  const theme = computed({
    get() {
      return $themeConfig.app.theme
    },
    set(value: typeof $themeConfig.app.theme) {
      $themeConfig.app.theme = value
    },
  })

  const skin = computed({
    get() {
      return $themeConfig.app.skin
    },
    set(value: typeof $themeConfig.app.skin) {
      $themeConfig.app.skin = value
    },
  })

  const isVerticalNavSemiDark = computed({
    get() {
      return $themeConfig.verticalNav.isVerticalNavSemiDark
    },
    set(value: typeof $themeConfig.verticalNav.isVerticalNavSemiDark) {
      $themeConfig.verticalNav.isVerticalNavSemiDark = value
    },
  })

  const syncVuetifyThemeWithTheme = () => {
    const vuetifyTheme = useTheme()
    watch(
      theme,
      (val) => {
        vuetifyTheme.global.name.value = val
      },
      { immediate: true },
    )
  }

  const layoutAttrs = computed(() => ({
    verticalNavAttrs: {
      wrapper: h(VThemeProvider, { tag: 'aside' }),
      wrapperProps: {
        withBackground: true,
        theme:
          isVerticalNavSemiDark.value && appContentLayoutNav.value === ContentLayoutNav.Vertical
            ? 'dark'
            : undefined,
      },
    },
  }))

  const injectSkinClasses = () => {
    const bodyClasses = document.body.classList
    const genSkinClass = (_skin?: string) => `skin--${_skin}`
    watch(
      skin,
      (val, oldVal) => {
        bodyClasses.remove(genSkinClass(oldVal))
        bodyClasses.add(genSkinClass(val))
      },
      { immediate: true },
    )
  }

  return {
    syncVuetifyThemeWithTheme,
    appContentWidth,
    theme,
    skin,
    navbarType,
    footerType,
    isVerticalNavCollapsed,
    isVerticalNavSemiDark,
    horizontalNavType,

    // skin
    injectSkinClasses,
    layoutClasses,

    // layouts exports
    layoutAttrs,
    setAppDir,
    isVerticalNavMini,
    dynamicI18nProps,
    appContentLayoutNav,
    isLessThanOverlayNavBreakpoint,
    isAppRtl,
    isNavbarBlurEnabled,
    switchToVerticalNavOnLtOverlayNavBreakpoint,
  }
}
