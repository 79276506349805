export default defineNuxtRouteMiddleware(async (to, from) => {
  // console.info('1.auth.global:', { from, to })

  const userStore = useUserStore()
  await userStore.loadUserData()

  const loginPageLink = localeLink({
    name: 'login',
    query: { to: to.name !== 'index' ? to.fullPath : undefined },
  })

  if (to.name?.toString().startsWith('private')) {
    const { canNavigate } = useAppAuthorizations()
    if (canNavigate(to)) {
      if (to.meta.redirectIfAuthenticated && userStore.isAuthenticated) {
        return await navigateTo(localeLink({ name: 'private' }))
      }
    } else if (userStore.isAuthenticated) {
      return await navigateTo(localeLink({ name: 'not-authorized' }))
    } else {
      return await navigateTo(loginPageLink)
    }
  }

  // redirect to login page when token is expired and user is under /private section
  if (
    from.name?.toString().startsWith('private') &&
    !userStore.isAuthenticated &&
    !to.name?.toString().startsWith('login')
  ) {
    return await navigateTo(loginPageLink)
  }
})
