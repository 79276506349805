import revive_payload_client_qsNNmDpc12 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mDWHerYuo6 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1ShxYwItIV from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0mJ6hAcyXU from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.9_vue@3.5.5/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_UcbHgxv9hW from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6OWE5u7pso from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9x3Ztjbfvq from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rimzRdu9BG from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_uBh5SVU9tL from "/home/runner/work/divine/divine/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/.nuxt/components.plugin.mjs";
import prefetch_client_W6u7QHs86h from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_H93bq2psat from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt-gtag@3.0.1_rollup@4.24.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_VTCstDttmI from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Jt6NiCOj4F from "/home/runner/work/divine/divine/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import index_0BaboSgNMq from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/layouts/index.ts";
import index_kyvreQaQna from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/vuetify/index.ts";
import casl_QTBkbB6FCs from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/casl.ts";
import electron_ipc_api_MSICFOjjP3 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/electron-ipc-api.ts";
import nuxt_error_hooks_b1Mep9do5h from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-error-hooks.ts";
import nuxt_page_hooks_ISYXsuIs9F from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/nuxt-page-hooks.ts";
import perfect_scrollbar_eDszbQJYAy from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/perfect-scrollbar.ts";
import trpc_client_sNkZYQPPl5 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/plugins/trpc-client.ts";
export default [
  revive_payload_client_qsNNmDpc12,
  unhead_mDWHerYuo6,
  router_1ShxYwItIV,
  _0_siteConfig_0mJ6hAcyXU,
  payload_client_UcbHgxv9hW,
  navigation_repaint_client_6OWE5u7pso,
  check_outdated_build_client_9x3Ztjbfvq,
  chunk_reload_client_rimzRdu9BG,
  plugin_vue3_uBh5SVU9tL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_W6u7QHs86h,
  plugin_client_H93bq2psat,
  switch_locale_path_ssr_VTCstDttmI,
  i18n_Jt6NiCOj4F,
  index_0BaboSgNMq,
  index_kyvreQaQna,
  casl_QTBkbB6FCs,
  electron_ipc_api_MSICFOjjP3,
  nuxt_error_hooks_b1Mep9do5h,
  nuxt_page_hooks_ISYXsuIs9F,
  perfect_scrollbar_eDszbQJYAy,
  trpc_client_sNkZYQPPl5
]