import routerOptions0 from "/home/runner/work/divine/divine/node_modules/.pnpm/nuxt@3.13.2_@azure+storage-blob@12.25.0_@types+node@20.16.12_eslint@9.12.0_rollup@4.24.0_sass_cv3gfkq5t2bf6z74hzpwl64v7a/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/divine/divine/apps/dvn-nuxt-platform/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}